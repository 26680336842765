
import {
  BLACKOUT_LOGS_FETCH_REQUEST,
  BLACKOUT_LOGS_FETCH_SUCCESS,
  BLACKOUT_LOGS_FETCH_ERROR,
  BLACKOUT_LOGS_UPDATE_REQUEST,
  BLACKOUT_LOGS_UPDATE_SUCCESS,
  BLACKOUT_LOGS_UPDATE_ERROR,
  INITIALIZE_STATE
} from '../actions/Actions';
const initialState = {
  isLoading: false
};

function BlackoutLogsReducer(state = initialState, action) {
  switch (action.type) {
    case BLACKOUT_LOGS_FETCH_REQUEST:
      return {
        isLoading: true,
        ...state,
      };
    case BLACKOUT_LOGS_FETCH_SUCCESS:
      return {
        isLoading: false,
        items: action.errorLogs
      };
    case BLACKOUT_LOGS_FETCH_ERROR:
      return {
        isLoading: false,
        ...state,
      };
    case BLACKOUT_LOGS_UPDATE_REQUEST:
      return {
        isLoading: true,
      };
    case BLACKOUT_LOGS_UPDATE_SUCCESS:
      return {
        isLoading: false,
        updatedLog: action.errorLog,
      };
    case BLACKOUT_LOGS_UPDATE_ERROR:
      return {
        isLoading: false,
        ...state,
      };
    case INITIALIZE_STATE:
      return initialState;
    default:
      return state;
  }
}

export default BlackoutLogsReducer;
