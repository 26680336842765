
import {
  CHANNELS_FETCH_REQUEST,
  CHANNELS_FETCH_SUCCESS,
  CHANNELS_FETCH_ERROR,
  INITIALIZE_STATE
} from '../actions/Actions';
const initialState = {
  isLoading: false
};

function ChannelsReducer(state = initialState, action) {
  switch (action.type) {
    case CHANNELS_FETCH_REQUEST:
      return {
        isLoading: true,
        ...state,
      };
    case CHANNELS_FETCH_SUCCESS:
      return {
        isLoading: false,
        items: action.channels
      };
    case CHANNELS_FETCH_ERROR:
      return {
        isLoading: false,
        ...state,
      };
    case INITIALIZE_STATE:
      return initialState;
    default:
      return state;
  }
}

export default ChannelsReducer;
