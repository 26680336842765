import {
  CONTENT_DETAILS_REQUEST,
  CONTENT_DETAILS_ERROR,
  CUSTOM_CONTENT_DETAILS_REQUEST,
  CUSTOM_CONTENT_DETAILS_SUCCESS,
  CUSTOM_CONTENT_DETAILS_ERROR,
  CUSTOM_CONTENT_DETAILS_UPDATE_REQUEST,
  CUSTOM_CONTENT_DETAILS_UPDATE_SUCCESS,
  CUSTOM_CONTENT_DETAILS_UPDATE_ERROR,
  UPDATE_CONTENT_ACTIVE_REQUEST,
  UPDATE_CONTENT_ACTIVE_SUCCESS,
  UPDATE_CONTENT_ACTIVE_ERROR,
  INITIALIZE_STATE
} from '../actions/Actions';
const initialState = {
  status: { clear: true, message: '' },
  isLoading: false,
};
function ContentDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case CONTENT_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CONTENT_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case CUSTOM_CONTENT_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CUSTOM_CONTENT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case CUSTOM_CONTENT_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case CUSTOM_CONTENT_DETAILS_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CUSTOM_CONTENT_DETAILS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case CUSTOM_CONTENT_DETAILS_UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_CONTENT_ACTIVE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_CONTENT_ACTIVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_CONTENT_ACTIVE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case INITIALIZE_STATE:
      return initialState;
    default:
      return state;
  }
}

export default ContentDetailsReducer;
