import {
  TEAMS_FETCH_REQUEST,
  TEAMS_FETCH_SUCCESS,
  TEAMS_FETCH_ERROR,
  INITIALIZE_STATE
} from '../actions/Actions';
const initialState = {
  items: []
};

function TeamsReducer(state = initialState, action) {
  switch (action.type) {
    case TEAMS_FETCH_REQUEST:
      return {
        ...state,
      };
    case TEAMS_FETCH_SUCCESS:
      return {
        items: action.teams
      };
    case TEAMS_FETCH_ERROR:
      return {
        ...state,
      };
    case INITIALIZE_STATE:
      return initialState;
    default:
      return state;
  }
}

export default TeamsReducer;
