import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import axios from 'axios';
import { initState } from "../../../actions/Actions";
import { API_ROOT } from '../../../api-config';
import { Card, CardBody, CardGroup, Col, Container, Form, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';

const emailValidator =
// eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      submitEnabled: false,
      invalidEmail: false,
      invalidCredentials: false,
      loading: false
    };
  }

  handleKeyDown = (e) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        if (this.state.submitEnabled) {
          this.handleSubmit();
        }
        break;
      default:
        break;
    }
  };

  isValidEmail = () =>
    emailValidator.test(String(this.state.email).toLowerCase());

  enableSubmit = () =>
    this.setState({ submitEnabled: this.isValidEmail() && this.state.password !== '' });

  emailWarning = () =>
    this.setState({ invalidEmail: !this.isValidEmail() });

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      invalidCredentials: false,
      invalidEmail: false
    }, this.enableSubmit);
  };

  handleLogin = () => {
    this.props.initState();
    this.props.handleLogin();
  };

  handleSubmit = () => {
    this.setState({loading: true});
    const handleError = () => {
      this.setState({invalidCredentials: true});
      this.setState({loading: false});
    };

    return axios.post(`${API_ROOT}/login`,
        JSON.stringify({"email": this.state.email, "password": this.state.password}),
        {'headers': {'content-type': 'application/json'}}
      )
      .then((response) => {
        document.cookie = `jwt=${response.headers.authorization}`;
        this.handleLogin();
      })
      .catch(() => handleError());
  };

  render() {
    const Button = withRouter(({ history }) => (
      <button
        type='button'
        onClick={() => {
          this.handleSubmit().then(() => {
            history.push('/')
          })
        }}
        color="primary"
        className="btn btn-primary"
        disabled={!this.state.submitEnabled}
      >
        Log In
      </button>
    ));

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1>Log In</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          type="email"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          onBlur={this.emailWarning}
                          onKeyDown={(e) => {
                            this.handleKeyDown(e);
                          }}
                          data-test-id="login-email-input"
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          type="password"
                          name="password"
                          autoComplete="current-password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          onKeyDown={(e) => {
                            this.handleKeyDown(e);
                          }}
                          data-test-id="login-password-input"
                        />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button />
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});

const mapDispatchToProps = dispatch => ({
  initState: () => dispatch(initState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
