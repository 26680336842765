import { SET_SEARCHABLE_GAMES, INITIALIZE_STATE } from '../actions/Actions';
const initialState = {
    searchableGames: []
};

function GamesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCHABLE_GAMES:
      return {
        ...state,
        searchableGames: action.games
      };
    case INITIALIZE_STATE:
      return initialState;
    default:
      return state;
    }
}

export default GamesReducer;
