import axios from 'axios';

import {
  API_ROOT,
  CONTESA_API_ROOT,
  CONTESA_GLOBAL_API_ROOT,
  GAMEFINDER_API_ROOT,
  CHANNEL_CLEANUP_BASE_URL,
} from '../api-config';

export const SET_SEARCHABLE_GAMES = 'SET_SEARCHABLE_GAMES';

export const TEAMS_FETCH_REQUEST = 'TEAMS_FETCH_REQUEST';
export const TEAMS_FETCH_SUCCESS = 'TEAMS_FETCH_SUCCESS';
export const TEAMS_FETCH_ERROR = 'TEAMS_FETCH_ERROR';

export const GAMEFINDER_TEAM_FETCH_REQUEST = 'GAMEFINDER_TEAM_FETCH_REQUEST';
export const GAMEFINDER_TEAM_FETCH_SUCCESS = 'GAMEFINDER_TEAM_FETCH_SUCCESS';
export const GAMEFINDER_TEAM_FETCH_ERROR = 'GAMEFINDER_TEAM_FETCH_ERROR';

export const GAMEFINDER_UPDATE_TEAM_REQUEST = 'GAMEFINDER_UPDATE_TEAM_REQUEST';
export const GAMEFINDER_UPDATE_TEAM_SUCCESS = 'GAMEFINDER_UPDATE_TEAM_SUCCESS';
export const GAMEFINDER_UPDATE_TEAM_ERROR = 'GAMEFINDER_UPDATE_TEAM_ERROR';

export const GAMEFINDER_META_FETCH_REQUEST = 'GAMEFINDER_META_FETCH_REQUEST';
export const GAMEFINDER_META_FETCH_SUCCESS = 'GAMEFINDER_META_FETCH_SUCCESS';
export const GAMEFINDER_META_FETCH_ERROR = 'GAMEFINDER_META_FETCH_ERROR';

export const GAMEFINDER_CREATE_CUSTOM_GAME_REQUEST = 'GAMEFINDER_CREATE_CUSTOM_GAME_REQUEST';
export const GAMEFINDER_CREATE_CUSTOM_GAME_SUCCESS = 'GAMEFINDER_CREATE_CUSTOM_GAME_SUCCESS';
export const GAMEFINDER_CREATE_CUSTOM_GAME_ERROR = 'GAMEFINDER_CREATE_CUSTOM_GAME_ERROR';

export const CHANNELS_FETCH_REQUEST = 'CHANNELS_FETCH_REQUEST';
export const CHANNELS_FETCH_SUCCESS = 'CHANNELS_FETCH_SUCCESS';
export const CHANNELS_FETCH_ERROR = 'CHANNELS_FETCH_ERROR';

export const GAMEFINDER_GAME_FETCH_REQUEST = 'GAMEFINDER_GAME_FETCH_REQUEST';
export const GAMEFINDER_GAME_FETCH_SUCCESS = 'GAMEFINDER_GAME_FETCH_SUCCESS';
export const GAMEFINDER_GAME_FETCH_ERROR = 'GAMEFINDER_GAME_FETCH_ERROR';

export const GAMEFINDER_UPDATE_GAME_REQUEST = 'GAMEFINDER_UPDATE_GAME_REQUEST';
export const GAMEFINDER_UPDATE_GAME_SUCCESS = 'GAMEFINDER_UPDATE_GAME_SUCCESS';
export const GAMEFINDER_UPDATE_GAME_ERROR = 'GAMEFINDER_UPDATE_GAME_ERROR';

export const BLACKOUT_LOGS_FETCH_REQUEST = 'BLACKOUT_LOGS_FETCH_REQUEST';
export const BLACKOUT_LOGS_FETCH_SUCCESS = 'BLACKOUT_LOGS_FETCH_SUCCESS';
export const BLACKOUT_LOGS_FETCH_ERROR = 'BLACKOUT_LOGS_FETCH_ERROR';

export const BLACKOUT_LOGS_UPDATE_REQUEST = 'BLACKOUT_LOGS_UPDATE_REQUEST';
export const BLACKOUT_LOGS_UPDATE_SUCCESS = 'BLACKOUT_LOGS_UPDATE_SUCCESS';
export const BLACKOUT_LOGS_UPDATE_ERROR = 'BLACKOUT_LOGS_UPDATE_ERROR';

export const CONTESA_FETCH_REQUEST = 'CONTESA_FETCH_REQUEST';
export const CONTESA_FETCH_SUCCESS = 'CONTESA_FETCH_SUCCESS';
export const CONTESA_FETCH_ERROR = 'CONTESA_FETCH_ERROR';

export const CONTENT_DETAILS_REQUEST = 'CONTENT_DETAILS_REQUEST';
export const CONTENT_DETAILS_ERROR = 'CONTENT_DETAILS_ERROR';

export const CUSTOM_CONTENT_DETAILS_REQUEST = 'CUSTOM_CONTENT_DETAILS_REQUEST';
export const CUSTOM_CONTENT_DETAILS_SUCCESS = 'CUSTOM_CONTENT_DETAILS_SUCCESS';
export const CUSTOM_CONTENT_DETAILS_ERROR = 'CUSTOM_CONTENT_DETAILS_ERROR';

export const CUSTOM_CONTENT_DETAILS_UPDATE_REQUEST = 'CUSTOM_CONTENT_DETAILS_UPDATE_REQUEST';
export const CUSTOM_CONTENT_DETAILS_UPDATE_SUCCESS = 'CUSTOM_CONTENT_DETAILS_UPDATE_SUCCESS';
export const CUSTOM_CONTENT_DETAILS_UPDATE_ERROR = 'CUSTOM_CONTENT_DETAILS_UPDATE_ERROR';

export const CLEANUP_LOGS_FETCH_REQUEST = 'CLEANUP_LOGS_FETCH_REQUEST';
export const CLEANUP_LOGS_FETCH_SUCCESS = 'CLEANUP_LOGS_FETCH_SUCCESS';
export const CLEANUP_LOGS_FETCH_ERROR = 'CLEANUP_LOGS_FETCH_ERROR';

export const UPDATE_CONTENT_ACTIVE_REQUEST =  'UPDATE_CONTENT_ACTIVE_REQUEST';
export const UPDATE_CONTENT_ACTIVE_SUCCESS =  'UPDATE_CONTENT_ACTIVE_SUCCESS';
export const UPDATE_CONTENT_ACTIVE_ERROR =  'UPDATE_CONTENT_ACTIVE_ERROR';

export const INITIALIZE_STATE = 'INITIALIZE_STATE';


function getAuth() {
  let auth = null;
  const item = document.cookie.split(';').filter((item) => item.includes('jwt='));
  if (item.length) {
    auth = item[0].trim().substr(4).trim();
  }
  return auth;
}

export const setSearchableGames = (games) => ({
  type: SET_SEARCHABLE_GAMES,
  games
});

// Initialize State
export const initState = () => ({
  type: INITIALIZE_STATE
});

// GameFinder Update Team
export const updateGameFinderTeamRequest = (team) => {
  const url = `${API_ROOT}/gamefinder/team/`;
  const config = { 'headers' : { 'content-type': 'application/json', 'authorization': getAuth()} };
  const body = JSON.stringify(team);

  return dispatch => {
    dispatch({ type: GAMEFINDER_UPDATE_TEAM_REQUEST });
    return axios.put(url, body, config)
    .then((res) => {
      if (res.status === 200) return res.data;
    })
    .then(body => {
      if (body) {
        return dispatch(updateGameFinderTeamSuccess(body));
      }
    })
    .catch((e) => {
      dispatch(updateGameFinderTeamsError(e));
      console.log('Gamefinder: Team API Error', e);
    });
  }
};

const updateGameFinderTeamSuccess = (team) => ({
  type: GAMEFINDER_UPDATE_TEAM_SUCCESS,
  team
});

const updateGameFinderTeamsError = (error) => ({
  type: GAMEFINDER_UPDATE_TEAM_ERROR,
  error
});

// Blackout Logs Fetch
export const fetchBlackOutLogsRequest = () => {
  const url = `${GAMEFINDER_API_ROOT}/blackouts/errors`;
  const config = { 'headers' : { 'content-type': 'application/json' } };

  return dispatch => {
    dispatch({ type: BLACKOUT_LOGS_FETCH_REQUEST });
    return axios.get(url, config)
      .then((res) => {
        if (res.status === 200) return res.data;
      })
      .then(body => {
        if (body) {
          return dispatch(fetchBlackOutLogsSuccess(body));
        }
      })
      .catch((e) => {
        dispatch(fetchBlackOutLogsError(e));
        console.log('Gamefinder: Error Log API Error', e);
      });
    }
};

const fetchBlackOutLogsSuccess = (errorLogs) => ({
  type: BLACKOUT_LOGS_FETCH_SUCCESS,
  errorLogs
});

const fetchBlackOutLogsError = (error) => ({
  type: BLACKOUT_LOGS_FETCH_ERROR,
  error
});

// Blackout Logs Update
export const updateBlackOutLogsRequest = () => {
  const url = `${API_ROOT}/blackoutlogs/`;
  const config = { 'headers' : { 'content-type': 'application/json' } };

  return dispatch => {
    dispatch({ type: BLACKOUT_LOGS_UPDATE_REQUEST });
    return axios.put(url, null, config)
      .then((res) => {
        return dispatch(updateBlackOutLogsSuccess(res.data));
      })
      .catch((e) => {
        dispatch(updateBlackOutLogsError(e));
        console.log('Gamefinder: Error Log API Error', e);
      });
    }
};

const updateBlackOutLogsSuccess = (errorLog) => ({
  type: BLACKOUT_LOGS_UPDATE_SUCCESS,
  errorLog
});

const updateBlackOutLogsError = (error) => ({
  type: BLACKOUT_LOGS_UPDATE_ERROR,
  error
});

// Contesa Fetch Teams
export const fetchTeamsRequest = (val) => {
  const url = `${CONTESA_API_ROOT}/teams?q=${val}`;
  const config = { 'headers' : { 'content-type': 'application/json' } };

  return (dispatch) => {
    dispatch({ type: TEAMS_FETCH_REQUEST });
    return axios.get(url, config)
      .then((res) => {
        if (res.status === 200) return res.data;
      })
      .then(body => {
        if (body.length > 0) {
          return dispatch(fetchTeamsSuccess(body));
        }
      })
      .catch((e) => {
        dispatch(fetchTeamsError(e));
        console.log('Contessa: Team API Error', e);
      });
    };
};

const fetchTeamsSuccess = (teams) => ({
  type: TEAMS_FETCH_SUCCESS,
  teams
});

const fetchTeamsError = (error) => ({
  type: TEAMS_FETCH_ERROR,
  error
});


// GameFinder_Team
export const fetchGameFinderTeamRequest = (teamId) => {
  const url = `${GAMEFINDER_API_ROOT}/teams/${teamId}`;
  const config = { 'headers' : { 'content-type': 'application/json' } };

  return (dispatch) => {
    dispatch({ type: GAMEFINDER_TEAM_FETCH_REQUEST });
    return axios.get(url, config)
      .then((res) => {
        if (res.status === 200) return res.data;
      })
      .then(body => {
        if (body) {
          return dispatch(fetchGameFinderTeamSuccess(body));
        }
      })
      .catch((e) => {
        dispatch(fetchGameFinderTeamError(e));
        console.log('Gamefinder: Single Team API Error', e);
      });
    };
};

const fetchGameFinderTeamSuccess = (team) => ({
  type: GAMEFINDER_TEAM_FETCH_SUCCESS,
  team
});

const fetchGameFinderTeamError = (error) => ({
  type: GAMEFINDER_TEAM_FETCH_ERROR,
  error
});

//Gamefinder Custom Game Meta Data
export const fetchGameFinderMetaRequest = () => {
  const url = `${GAMEFINDER_API_ROOT}/gameoptions`;
  const config = { 'headers' : { 'content-type': 'application/json' } };

  return (dispatch) => {
    dispatch({ type: GAMEFINDER_META_FETCH_REQUEST });
    return axios.get(url, config)
      .then((res) => {
        if (res.status === 200) return res.data;
      })
      .then(body => {
        if (body) {
          return dispatch(fetchGameFinderMetaSuccess(body));
        }
      })
      .catch((e) => {
        dispatch(fetchGameFinderMetaError(e));
        console.log('Gamefinder: Search API Error', e);
      });
    };
};

const fetchGameFinderMetaSuccess = (meta) => ({
  type: GAMEFINDER_META_FETCH_SUCCESS,
  meta
});

const fetchGameFinderMetaError = (error) => ({
  type: GAMEFINDER_META_FETCH_ERROR,
  error
});

//Contesa Channels Data
export const fetchChannelsRequest = (query) => {
  const url = `${CONTESA_API_ROOT}/channels?q=${query}`;
  const config = { 'headers' : { 'content-type': 'application/json' } };

  return (dispatch) => {
    dispatch({ type: CHANNELS_FETCH_REQUEST });
    return axios.get(url, config)
      .then((res) => {
        if (res.status === 200) return res.data;
      })
      .then(body => {
        if (body) {
          return dispatch(fetchChannelsSuccess(body));
        }
      })
      .catch((e) => {
        dispatch(fetchChannelsError(e));
        console.log('Gamefinder: Search API Error', e);
      });
    };
};

const fetchChannelsSuccess = (channels) => ({
  type: CHANNELS_FETCH_SUCCESS,
  channels
});

const fetchChannelsError = (error) => ({
  type: CHANNELS_FETCH_ERROR,
  error
});

//GameFinder
export const createCustomGameRequest = (gameObject, ownProps) => {
  const url = `${API_ROOT}/gamefinder/custom/games`;
  const config = { 'headers' : { 'content-type': 'application/json', 'authorization': getAuth()} };
  const body = JSON.stringify(gameObject);

  return (dispatch) => {
    dispatch({ type: GAMEFINDER_CREATE_CUSTOM_GAME_REQUEST });
    return axios.post(url, body, config)
    .then((res) => {
      if (res.status === 200) return res.data;
    })
    .then(body => {
      if (body) {
        ownProps.history.push(`/games/${body.game.id}`)
        return dispatch(createCustomGameSuccess(body.game));
      }
    })
    .catch((e) => {
      dispatch(createCustomGameError(e));
      console.log('Gamefinder: Create Custom Game API Error', e);
    });
  };
};

const createCustomGameSuccess = (newGame) => ({
  type: GAMEFINDER_CREATE_CUSTOM_GAME_SUCCESS,
  newGame
});

const createCustomGameError = (error) => ({
  type: GAMEFINDER_CREATE_CUSTOM_GAME_ERROR,
  error
});

export const fetchGameFinderGameRequest = (gameId) => {
  const url = `${GAMEFINDER_API_ROOT}/custom/games/${gameId}`;
  const config = { 'headers': { 'content-type': 'application/json' } };

  return dispatch => {
    dispatch({ type: GAMEFINDER_GAME_FETCH_REQUEST });
    return axios.get(url, config)
      .then((res) => {
        return res.data;
      })
      .then(body => {
          return dispatch(fetchGameFinderGameSuccess(body));
      })
      .catch((e) => {
        dispatch(fetchGameFinderGameError(e));
        console.log('Gamefinder: Fetch API Error', e);
      });
    };
};

const fetchGameFinderGameSuccess = (game) => ({
  type: GAMEFINDER_GAME_FETCH_SUCCESS,
  game
});

const fetchGameFinderGameError = (error) => ({
  type: GAMEFINDER_GAME_FETCH_ERROR,
  error
});

export const updateGameFinderGameRequest = (gameId, gameObject, history) => {
  const url = `${API_ROOT}/gamefinder/custom/games/${gameId}`;

  return (dispatch) => {
    dispatch({ type: GAMEFINDER_UPDATE_GAME_REQUEST });
    const config = { 'headers': {
        'Content-Type': 'application/json',
        'Authorization': getAuth(),
      }
    };
    const body = JSON.stringify(gameObject);

    return axios.put(url, body, config)
      .then((res) => {
        if (res.data) {
          history.push(`/games`);
          return dispatch(updateGameFinderGameSuccess(res.data));
        }
      })
      .catch((e) => {
        dispatch(updateGameFinderGameError(e));
        console.log('Gamefinder: Create Custom Game API Error', e);
      });
    };
};

const updateGameFinderGameSuccess = (updateGame) => ({
  type: GAMEFINDER_UPDATE_GAME_SUCCESS,
  updateGame
});

const updateGameFinderGameError = (error) => ({
  type: GAMEFINDER_UPDATE_GAME_ERROR,
  error
});

// Contesa
export const fetchContesaRequest = (query) => {
  const url = `${CONTESA_GLOBAL_API_ROOT}?q=${query}&includedIndexes=FRANCHISES`;
  const config = { 'headers': { 'content-type': 'application/json' } };

  return dispatch => {
    dispatch({ type: CONTESA_FETCH_REQUEST });
    return axios.get(url, config)
      .then((res) => {
        if (res.status === 200) return res.data;
      })
      .then(body => {
        if (body) {
          return dispatch(fetchContesaSuccess(body));
        }
      })
      .catch((e) => {
        dispatch(fetchContesaError(e));
        console.log('Contesa: Fetch API Error', e);
      });
    };
};

const fetchContesaSuccess = (data) => ({
  type: CONTESA_FETCH_SUCCESS,
  data
});

const fetchContesaError = (error) => ({
  type: CONTESA_FETCH_ERROR,
  error
});

// Content Curation Actions
export const fetchContentDetailsRequest = ({ type, slug }) => {
  const url = `${API_ROOT}/franchises/slug/${type}/${slug}`;
  const config = { 'headers': { 'content-type': 'application/json', authorization: getAuth() } };

  return dispatch => {
    dispatch({ type: CONTENT_DETAILS_REQUEST });
    return axios.get(url, config)
      .then((res) => {
        if (res.status === 200) return res.data;
      })
      .then(body => {
        if (body) {
          return dispatch(fetchCustomContentDetailsRequest(body));
        }
      })
      .catch((e) => {
        dispatch(fetchContentDetailsError(e));
        console.log('EPG Service: Fetch API Error', e);
      });
    };
};

const fetchContentDetailsError = (error) => ({
  type: CONTENT_DETAILS_ERROR,
  error
});

// Custom Content Actions
export const fetchCustomContentDetailsRequest = (content) => {

  const url = `${API_ROOT}/cms/franchise/${content.urlSlug}`;
  const config = { 'headers': { 'content-type': 'application/json', authorization: getAuth() } };

  return dispatch => {
    dispatch({ type: CUSTOM_CONTENT_DETAILS_REQUEST });
    return axios.get(url, config)
      .then((res) => {
        if (res.status === 200) return res.data;
      })
      .then(body => {
        content.description = (body && body.previousDescription)
          ? body.previousDescription
          : (content.description ? content.description : '');
        content.customDescription = (body && body.newDescription)
          ? body.newDescription
          : '';
        return dispatch(fetchCustomContentDetailsSuccess(content));
      })
      .catch(() => {
        console.log('Custom Content Does Not Exist for this Franchise');
        content.description = content.description ? content.description : '';
        content.customDescription = '';
        return dispatch(fetchCustomContentDetailsSuccess(content));
      })
      .catch((e) => {
        console.error('Custom Content Curation Endpoint: Fetch Error', e);
        return dispatch(fetchCustomContentDetailsError(e));
      });
    };
};

const fetchCustomContentDetailsSuccess = (data) => ({
  type: CUSTOM_CONTENT_DETAILS_SUCCESS,
  data
});

const fetchCustomContentDetailsError = (error) => ({
  type: CUSTOM_CONTENT_DETAILS_ERROR,
  error
});

// update content
export const updateCustomContentDetailsRequest = (urlSlug, request) => {

  const url = `${API_ROOT}/cms/franchise/${urlSlug}`;
  const config = { 'headers': { 'content-type': 'application/json', authorization: getAuth() } };
  const body = JSON.stringify(request);

  return dispatch => {
    dispatch({ type: CUSTOM_CONTENT_DETAILS_UPDATE_REQUEST });
    return axios.put(url, body, config)
      .then((res) => {
        return dispatch(updateCustomContentDetailsSuccess(res));
      })
      .catch((e) => {
        console.log('Custom Content Curation Endpoint: Update Error', e);
        return dispatch(updateCustomContentDetailsError(e));
      });
    }
};

const updateCustomContentDetailsSuccess = (data) => ({
  type: CUSTOM_CONTENT_DETAILS_UPDATE_SUCCESS,
  data
});

const updateCustomContentDetailsError = (error) => ({
  type: CUSTOM_CONTENT_DETAILS_UPDATE_ERROR,
  error
});

//change active Until state based by sending active in request body
export const updateContentActiveRequest = (urlSlug, request) => {
  const url = `${API_ROOT}/cms/update/${urlSlug}`;
  const config = { 'headers': { 'content-type': 'application/json', authorization: getAuth() } };
  const body = JSON.stringify(request);

  return dispatch => {
    dispatch({ type: UPDATE_CONTENT_ACTIVE_REQUEST });
    return axios.put(url, body, config)
        .then((res) => {
          return dispatch(updateContentActiveSuccess(res));
        })
        .catch((e) => {
          console.log('change active until date: Update Error', e);
          return dispatch(updateContentActiveError(e));
        });
  }
};

const updateContentActiveSuccess = (data) => ({
  type: UPDATE_CONTENT_ACTIVE_SUCCESS,
  data
});

const updateContentActiveError = (error) => ({
  type: UPDATE_CONTENT_ACTIVE_ERROR,
  error
});

// Cleanup Logs Fetch
export const fetchCleanupLogsRequest = (startDate, endDate) => {
  const url = `${CHANNEL_CLEANUP_BASE_URL}?startDate=${startDate}&endDate=${endDate}`;
  const config = { 'headers' : { 'content-type': 'application/json', 'authorization': getAuth() } };

  return dispatch => {
    dispatch({ type: CLEANUP_LOGS_FETCH_REQUEST });
    return axios.get(url, config)
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        }
      })
      .then(body => {
        if (body) {
          return dispatch(fetchCleanupLogsSuccess(body));
        }
      })
      .catch((e) => {
        dispatch(fetchCleanupLogsError(e));
        console.log('RetrieveCleanupContentLogs: Error Log API Error', e);
      });
  };
};

const fetchCleanupLogsSuccess = (cleanupLogs) => ({
  type: CLEANUP_LOGS_FETCH_SUCCESS,
  cleanupLogs
});

const fetchCleanupLogsError = (error) => ({
  type: CLEANUP_LOGS_FETCH_ERROR,
  error
});

