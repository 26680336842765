
import {
  CONTESA_FETCH_REQUEST,
  CONTESA_FETCH_SUCCESS,
  CONTESA_FETCH_ERROR,
  INITIALIZE_STATE,
} from '../actions/Actions';
const initialState = {
  isLoading: false
};

function ContesaReducer(state = initialState, action) {
  switch (action.type) {
    case CONTESA_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CONTESA_FETCH_SUCCESS:
      return {
        isLoading: false,
        data: action.data
      };
    case CONTESA_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case INITIALIZE_STATE:
      return initialState;
    default:
      return state;
  }
}

export default ContesaReducer;
