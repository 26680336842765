
import {
  GAMEFINDER_TEAM_FETCH_REQUEST,
  GAMEFINDER_TEAM_FETCH_SUCCESS,
  GAMEFINDER_TEAM_FETCH_ERROR,
  GAMEFINDER_UPDATE_TEAM_REQUEST,
  GAMEFINDER_UPDATE_TEAM_SUCCESS,
  GAMEFINDER_UPDATE_TEAM_ERROR,
  GAMEFINDER_META_FETCH_REQUEST,
  GAMEFINDER_META_FETCH_SUCCESS,
  GAMEFINDER_META_FETCH_ERROR,
  GAMEFINDER_GAME_FETCH_REQUEST,
  GAMEFINDER_GAME_FETCH_SUCCESS,
  GAMEFINDER_GAME_FETCH_ERROR,
  GAMEFINDER_UPDATE_GAME_ERROR,
  INITIALIZE_STATE,
} from '../actions/Actions';
const initialState = {
  status: { clear: true, message: '' },
  isLoading: false
};

function GameFinderReducer(state = initialState, action) {
  switch (action.type) {
    case GAMEFINDER_TEAM_FETCH_REQUEST:
      return {
        ...state,
        status: {clear: true, message: ""},
        isLoading: true
      };
    case GAMEFINDER_TEAM_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedTeam: action.team
      };
    case GAMEFINDER_TEAM_FETCH_ERROR:
      return {
        ...state,
        status: {clear: false, message: action.error.response.data.message},
        isLoading: false
      };
    case GAMEFINDER_UPDATE_TEAM_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GAMEFINDER_UPDATE_TEAM_SUCCESS:
      return {
        isLoading: false,
        status: {clear: true, message: "Update Successful"},
        selectedTeam: action.team,
      };
    case GAMEFINDER_UPDATE_TEAM_ERROR:
      return {
        ...state,
        status: {clear: false, message: action.error.response.data.message},
        isLoading: false
      };
    case GAMEFINDER_META_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GAMEFINDER_META_FETCH_SUCCESS:
      const gameTypes = {};
      const blackoutTypes = {};

      action.meta.gameTypes.forEach((gameType) => {
        gameTypes[gameType.label] = gameType.value;
      });

      action.meta.blackoutTypes.forEach(blackoutType => blackoutTypes[blackoutType.label] = blackoutType.value);

      return {
        ...state,
        isLoading: false,
        blackoutTypes,
        gameTypes,
        rating: action.meta.rating
      };
    case GAMEFINDER_META_FETCH_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case GAMEFINDER_GAME_FETCH_REQUEST:
      return {
        isLoading: true,
        ...state,
      };
    case GAMEFINDER_GAME_FETCH_SUCCESS:
      const newGame = { ...action.game.game };
      newGame['awayTeam'] = { ...action.game.awayTeam, searchBoxName: `(${newGame.league.toUpperCase()})${action.game.awayTeam.city} ${action.game.awayTeam.name}` };
      newGame['homeTeam'] = { ...action.game.homeTeam, searchBoxName: `(${newGame.league.toUpperCase()})${action.game.homeTeam.city} ${action.game.homeTeam.name}` };
      return {
        ...state,
        isLoading: false,
        game: newGame
      };
    case GAMEFINDER_GAME_FETCH_ERROR:
      return {
        isLoading: false,
        ...state,
      };
    case GAMEFINDER_UPDATE_GAME_ERROR:
      return {
        isLoading: false,
        updateError: { ...action },
        ...state,
      };
    case INITIALIZE_STATE:
      return initialState;
    default:
      return state;
  }
}

export default GameFinderReducer;
