export default {
  items: [
    {
      name: 'Discovery Admin',
      url: '/dashboard',
      icon: 'cui-magnifying-glass',
    },
    {
      title: true,
      name: 'Gamefinder',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Games',
      url: '/games',
      icon: 'cui-globe',
    },
    {
      name: 'Teams',
      url: '/teams',
      icon: 'cui-globe',
    },
    {
      name: 'Blackout Error Logs',
      url: '/blackout-logs',
      icon: 'cui-globe',
    },
    {
      title: true,
      name: 'EPG Content',
      wrapper: {
        element: '',
        attributes: {},
      },
    },
    {
      name: 'Content',
      url: '/content',
      icon: 'cui-globe',
    },
    {
      name: 'Cleanup Logs',
      url: '/cleanup-logs',
      icon: 'cui-globe',
    },
    {
      name: 'Cleanup Cache',
      url: '/cleanup-cache',
      icon: 'cui-globe',
    },
    {
      name: 'Single Channel',
      url: '/single-channel',
      icon: 'cui-globe',
    },
  ],
};
