import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import { DefaultLayout } from './containers';
import { Login, Page404 } from './views/Pages';
import axios from 'axios';
import { API_ROOT } from './api-config';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: this.getCookie('jwt') !== null
    };
  }

  componentWillMount() {
    this.checkIfSessionIsActive();
  }

  getCookie(cookieName) {
    /***
    Since cookies are stored as follows:
     "{name}={value}; {name}={value}; ..."

    In order to retrieve a specific cookie value, we just need to get the string that is after "; {name}="
     and before the next ";".
    Before we do any processing, we prepend the cookie string with "; ",
     so that every cookie name, including the first one, is enclosed with "; " and "=".

    So our cookieString looks like:
     "; {name}={value}; {name}={value}; ..."

    Once we have the updated cookie array, we split the array on the cookieName we are passing in so that if cookieName is found,
     we now have 2 parts to the array:
     All the cookies before the one we are getting, and all the cookies including and after the one we are getting.

    Should cookieName be found, we know that the second part of the array will begin with that cookie's value
     (based on the fact that we split just after 'cookieName=' ) so we start with cookiesArray[1]

    Next, we split cookiesArray[1] on ';' and grab the first ([0]) element to remove all remaining cookies.
    Then we return this string as the value for the cookieName we passed on

    If cookiesArray does not have a length of 2, then we know that the provided cookieName was NOT found and we return null
    */

    const cookieString = `; ${document.cookie}`;
    const cookiesArray = cookieString.split(`; ${cookieName}=`);
    return cookiesArray.length === 2 ? cookiesArray[1].split(";")[0] : null;
  }

  checkIfSessionIsActive = () => {
    const jwt = this.getCookie('jwt');
    if(jwt !== null ){
      axios.get(`${API_ROOT}/session-active`, { 'headers': { 'Authorization': jwt }})
        .catch(() => {
          this.handleLogout()
        });
    }
  };

  handleLogin = () => this.setState({ loggedIn: true });

  handleLogout = () => {
    document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.setState({ loggedIn: false });
  };

  render() {
    const PrivateRoute = ({loggedIn}) => {
      return (
        <Route
          render={() => loggedIn === true
            ? <DefaultLayout
              handleLogout={this.handleLogout}
              checkIfSessionIsActive={this.checkIfSessionIsActive}
            />
            : <Redirect to={{pathname: '/login'}} />}
        />
      )
    };
    return (
      <HashRouter>
        <Switch>
          <Route
            exact path="/login"
            name="Login Page"
            render={() => <Login handleLogin={this.handleLogin} />} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <PrivateRoute
            loggedIn={this.state.loggedIn}
            path='/'
          />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
