import { applyMiddleware } from 'redux';
import { createInjectStore } from 'redux-injector';
import Logger from 'redux-logger';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import {
  BlackOutLogsReducer,
  ContentDetailsReducer,
  ChannelsReducer,
  ContesaReducer,
  GamesReducer,
  GameFinderReducer,
  TeamsReducer,
  CleanupLogsReducer,
} from './reducers';

const rootReducer = {
  activeContentDetails: ContentDetailsReducer,
  channels: ChannelsReducer,
  contesa: ContesaReducer,
  games: GamesReducer,
  gameFinder: GameFinderReducer,
  teams: TeamsReducer,
  blackOutLogs: BlackOutLogsReducer,
  cleanupLogs: CleanupLogsReducer
};

const history = createBrowserHistory();

const middlewares = [
  thunk,
  Logger,
];

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createInjectStore);

/**
 * Initializes the redux store with middlewares and reducers.
 * Note that redux-injector has been implemented to allow dynamically adding
 * reducers at run-time.
 *
 * @todo: Check on using redux devtools in prod code
 */
const store = createStoreWithMiddleware(
  rootReducer,
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

// Export default separately for esdoc
export default store;
export { history };
