let backendHost;
const hostname = window && window.location && window.location.hostname;

if (hostname.includes('d.sling.com')) {
  backendHost = 'https://ms.d.sling.com/sling-api';
} else if (hostname.includes('q.sling.com')) {
  backendHost = 'https://ms.q.sling.com/sling-api';
} else if (hostname.includes('b.sling.com')) {
  backendHost = `https://ms.b.sling.com/sling-api`;
} else if (hostname.includes('sling.com')) {
  backendHost = `https://ms.p.sling.com/sling-api`;
} else if (window.Cypress) {
  backendHost = 'http://localhost:7777';
} else if(hostname.includes('localhost')) {
  backendHost = 'https://ms.d.sling.com/sling-api';
} else if(hostname.includes('gamefinder-admin.review')) {
  backendHost = 'https://ms.q.sling.com/sling-api';
} else {
  backendHost = 'https://ms.d.sling.com/sling-api';
}

export const API_ROOT = `${backendHost}/alpha/epg-admin-tool`;
export const EPG_PUB_ROOT = `${backendHost}/alpha/epg-publisher`;
export const CONTESA_API_ROOT = `${backendHost}/alpha/content-search`;
export const CONTESA_GLOBAL_API_ROOT = `${backendHost}/alpha/global-search`;
export const REFRESH_BLACKOUTS_URL = `${backendHost}/alpha/gamefinder/refresh-blackouts`;
export const REFRESH_GAMES_URL = `${backendHost}/alpha/gamefinder/publish`;
export const REFRESH_GAME_SEARCH_URL = `${EPG_PUB_ROOT}/publish/gameSearch`;
export const GAMEFINDER_API_ROOT = `${backendHost}/alpha/gamefinder`;
export const CLEAR_CACHE_URL = `${API_ROOT}/warmCache`;
export const CACHE_CHECK_URL = `${API_ROOT}/cache-check`;
export const PUBLISH_SEARCH_URL = `${API_ROOT}/publishSearchContentToContesa`;
export const CHANNEL_CLEANUP_BASE_URL = `${API_ROOT}/cleanup-content`;
