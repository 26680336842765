import {
  CLEANUP_LOGS_FETCH_REQUEST,
  CLEANUP_LOGS_FETCH_SUCCESS,
  CLEANUP_LOGS_FETCH_ERROR,
  INITIALIZE_STATE
} from '../actions/Actions';

const initialState = {
  isLoading: false
};

function CleanupLogsReducer(state = initialState, action) {
  switch (action.type) {
    case CLEANUP_LOGS_FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CLEANUP_LOGS_FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: [...action.cleanupLogs],
      };
    case CLEANUP_LOGS_FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case INITIALIZE_STATE:
      return initialState;
    default:
      return state;
  }
}

export default CleanupLogsReducer;
