import React, { Component } from 'react';
import { Nav } from 'reactstrap';
import PropTypes from 'prop-types';

import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/logo.png'
import heartLogo from '../../assets/img/brand/sling_heart.png'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {

  componentDidMount() {
    // 300,000 == 5 minutes
    this.sessionCheckTimer = setInterval(()=> this.props.checkIfSessionIsActive(), 300000);
  }

  componentWillUnmount() {
    clearInterval(this.sessionCheckTimer);
    this.sessionCheckTimer = null;
  }

  logout = () => {
    this.props.handleLogout();
  };

  render() {
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 60, height: 35, alt: 'CoreUI Logo' }}
          minimized={{ src: heartLogo, width: 35, height: 30, alt: 'CoreUI Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <Nav className="d-md-down-none" navbar>
        </Nav>
        <Nav className="ml-auto" navbar>
          <button data-test-id="logout-button" className="btn" onClick={this.logout} >Logout</button>
        </Nav>
        {/*<AppAsideToggler className="d-lg-none" mobile />*/}
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;